<!--正常授信 上传图片-->
<template>
  <div class="upload">
    <div class='upload-header' v-if="uploadType!='qita'">
              <span>不会操作？点击查看</span>
              <span class="header-text" @click="showMadel">操作指引<i></i></span>
    </div>
    <div class='list'>
          <div class="imgBox" v-for="(item, index) in imgData" :key="index">
            <img :src="item" alt="" />
            <span @click="del(index)"><img src="./img/delete.png" alt="" /></span>
          </div>
          <div class="uploadImg" @click="selectImg">
            <img src="./img/upload.png" alt="" />
          </div>
    </div>
    
    <div class="submit" @click="submit">提交</div>
    <!--芝麻分 截图弹窗 -->
     <Modal
            :show="shutBack"
            @cancel="gocancel"
            :showCancel="true"
            class="upload-modal"
            :title="modalTitle"
            v-if="uploadType!='qita'" 
      >
      
         <div class='upload-mians' v-if="uploadType=='zhima'">
                <img class="ios-img"  src='./img/f-img.png' alt=""/>
            </div>
            <div class='upload-mians' v-if="uploadType=='shebao'">
                <img class="ios-img-sb"  src='./img/sb-img.png' alt=""/> 
            </div>
            <div class='upload-mians' v-if="uploadType=='gongjijin'">
                <img class="ios-img-gjj"  src='./img/gjj-img.png' alt=""/>
            </div>
        <div class='upload-btn' @click="modalBtn">我知道了</div>
      </Modal>
  </div>
</template>
<script>
import { toRefs, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import API from "../../server/api";
import { dealImage } from "../../utils/index";
import Modal from '@/components/Modal/Modal.vue'
export default {
  components: {
        Modal
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      fileList: [],
      fileName: "",
      fileType: "",
      num: 0,
      uploadName: "",
      uploadType: "",
      creditNo: "",
      userId: "",
      imgUrl: "",
      imgList: [],
      imgData: [], // 图片路径
      shutBack: false,//弹窗
      modalTitle:'如何操作截图'
    });
    //操作指引
   const showMadel=()=>{
      console.log(776)
      state.shutBack=true
    }
    //选择照片
    const selectImg = () => {
      APP.ChooseImg(function(res){
        if(res){
            const result=JSON.parse(res)
            console.log(result,result.tempFilePaths,'result')
            // state.tempFilePath=result.tempFilePath
            uploadOSS(result.tempFilePaths)
        }
      })
    };
    const uploadOSS = async (file) => {
      for (var i = 0; i < file.length; i++) {
        
        const fileKey = "JYD-FILE";
        const res = await API.uploadOss({
          fileName: "jpg",
          fileType: fileKey,
        });
      
        if (res.code == 200) {
          const {result}=res
          result.file=file[i]
          result.fileState='图片'
          console.log(result, "file");
          const {host,dir}=result
          APP.uploadFile(result,function(res){
            const {statusCode}=JSON.parse(res)
            if(statusCode==200){
              // const result=JSON.parse(res)
              console.log(statusCode,'上传成功了')
              const imgUrl=host+dir
              state.imgData.push(imgUrl)
              console.log(state.imgData,'上传成功了')
            }
           
          })
        }
      }
    };
    // 上传图片
    const uploadFile = async (data) => {
      const imgFile = await API.uploadFile(data);
      if (imgFile) {
        Toast.success("上传成功");
        setTimeout(() => {
          APP.Back(1);
        }, 1000);
      }
    };
    // 获取图片列表
    const getPhotoList = async () => {
      const photos = await API.getPhotoList({
        creditNo: state.creditNo,
        type: state.uploadType,
      });
      console.log(photos.echoList, "图片");
      state.imgData = photos.echoList;
    };
   
    const gocancel = () => {
      console.log('关闭弹窗')
      state.shutBack = false
     
    }
    const modalBtn=()=>{
       console.log('我知道了')
      state.shutBack = false
    }
    onMounted(() => {
      const routeInfo = route.query.jsParam;
      const arr = routeInfo.split("&");
      const obj = {};
      for (var i = 0; i < arr.length; i++) {
        const aa = arr[i].split("=");
        obj[aa[0]] = aa[1];
      }
      state.routeInfo = obj;
      console.log(state.routeInfo, "route444");
      state.uploadName = state.routeInfo.everyName;
      state.uploadType = state.routeInfo.type;
      getPhotoList();
      const {dataType,datalength}=state.routeInfo
      if(dataType=='jietu'){
        
        if(datalength>0){
           state.shutBack = false
        }else{
          console.log(state.imgData,'888')
          state.shutBack = true
        }

      }
      if(state.uploadType=='shebao'){
        console.log('shebao')
          state.modalTitle="如何操作社保截图"
      }else if(state.uploadType=='gongjijin'){
         console.log('gongjijin')
          state.modalTitle="如何操作公积金截图"
      }
      state.creditNo = localStorage.getItem("creditNo");
      state.userId = localStorage.getItem("userId");
     
    });
    const del = (n) => {
      state.imgData.splice(n, 1);
    };
    const submit = () => {
      const imgModel = {};
      imgModel.imgData = state.imgData;
      imgModel.type = state.uploadType;
      if (state.imgData.length === 0) {
        Toast.fail("请先上传附件!");
        return false;
      } else {
        uploadFile({
          creditNo: state.creditNo,
          imgModel: imgModel,
          userId: state.userId,
        });
      }
    };

    return {
      ...toRefs(state),
      submit,
      del,
      selectImg,
      gocancel,
      modalBtn,
      showMadel
    };
  },
};
</script>
<style lang="less" src="./index.less" scoped></style>

